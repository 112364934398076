<template>
  <div class="animated fadeIn">
    <b-card>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
        :width="30"
        :height="30"
      ></loading>
      <b-alert variant="warning" v-show="json == null" show class="mb-0"
        >Мэдээлэл байхгүй байна</b-alert
      >
      <tree-view
        :data="json"
        :options="{ maxDepth: 2, rootObjectKey: 'ХУР' }"
      ></tree-view>
    </b-card>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Customers.xypInfo",
  // components: {TreeView} ,
  data: function() {
    return {
      json: {},
      isLoading: false,
    };
  },
  created: function() {
    if (this.checkPermission("admin.customer.xyp")) {
      this.loadData();
    } else {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_customer_xyp_info",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            // //console.log(response);
            this.isLoading = false;
            if (response.body) {
              this.$data.json = JSON.parse(response.body.json);
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
